@import "./app/brand-variables";

body {
	font-family: "Open Sans", "Helvetica Neue",Helvetica,Arial,sans-serif;
}

.navbar-default {
	background: #fff;
	.navbar-header {
		.navbar-brand {
			color: $main-color;
		  	text-transform: none;
		  	-webkit-font-smoothing: antialiased;
		}
	}
	.nav {
		> li {
			&.active {
				> a {
					color: $main-color !important;
				}
			}
			> a {
			  color: $brand-font-color;
			  font-weight: normal;
			  text-transform: none;
			}
		}
	}
	&.affix {
		.navbar-header {
			.navbar-brand {
				color: $main-color;
			}
		}
	}
}

header {
	background: $main-color;
	.header-content {
		.header-content-inner {
			h1 {
				//text-transform: none;
				//font-weight: normal;
				//font-style: italic;
			  background: rgba(0, 0, 0, 0.43);
			  padding: 10px;
			  display: inline-block;
			  //text-transform: uppercase;
			  //font-weight: bold;
			  //font-style: normal;
			}
			p {
				color: #fff;
				background-color: rgba(0, 0, 0, 0.2);
				font-size: 20px;
			}
		}
	}
}

.internal-page {
	header {
		padding-top: 50px;
	}
}

hr {
	border-color: $brand-font-color-second;
}

.service-box h3,
.section-heading {
	color: #999;
}

.row-colored {
	.section-heading {
		color: #fff;
	}
}

a,
.fa,
.text-primary {
	color: #04c1f9;
}

.btn-primary {
	background: $main-color;
	border: 2px solid $brand-font-color-second;
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary.focus,
.btn-primary:active,
.btn-primary.active,
.open > .dropdown-toggle.btn-primary {
  border-color: $brand-font-color-second;
  color: $brand-font-color-third;
  background-color: $secondary-color;
}

.navbar-default .nav > li>a:hover,
.navbar-default .nav>li>a:focus:hover,
.navbar-default.affix .nav > li>a:hover,
.navbar-default.affix .nav>li>a:focus:hover {
  color: $main-color;
}

.bg-primary {
	background-color: $secondary-color;
	color: $brand-font-color-third;
}

.bg-dark {
	background-color: $background-color-dark;
	.copy {
		color: $brand-font-color;
	}
}

footer {
	background: $background-color-dark;
	color: $dark-font-color;
	ul {
	  list-style: none;
	  padding-left: 0px;
	}
}

.text-faded {
	color: rgba(0, 0, 0, 0.5);
}

@media (min-width: 768px) {
  header {
	min-height: 85%;
  }
}
