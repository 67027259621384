$main-color: #04c1f9;
$secondary-color: #FFEB3B;

$internal-border-color: #eaeaea;
$box-border-shadow: 2px 2px 3px #ccc;

$brand-font-color: #b9b9b9;
$brand-font-color-second: #fff;
$brand-font-color-third: #5f5b3c;
$background-hover-color: #fdfdfd;

$background-color-dark: #424242;
$dark-font-color: #d2d2d2;

$paragraph-font: 'Open Sans', sans-serif;
$paragraph-font-size: 14px;

$dashboard-font: 'HelveticaNeue-Light', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial, sans-serif;

$brand-primary-green: $brand-font-color;
$internal-page-border: 1px solid $internal-border-color;